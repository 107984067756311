import { createInertiaApp } from "@inertiajs/svelte";
import * as Sentry from "@sentry/svelte";
import type { SvelteComponent } from "svelte";
import "vite/modulepreload-polyfill";
import "../styling/main.css";
import IconsLayout from "./layout/IconsLayout.svelte";

interface ModuleImportInterface {
    default: SvelteComponent;
    layout?: SvelteComponent;
}

// global env var, set in base.blade.php
declare var backend_env: string;

// copy & paste from https://docs.sentry.io/platforms/javascript/guides/svelte/

Sentry.init({
    dsn: "https://7f3ab838cf054c43ad2d2d7bf4bd695a@glitchtip.lernetz.host/19",
    environment: backend_env ?? "unknown",
    integrations: [
        //Sentry.browserTracingIntegration(),
        //Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
        "localhost",
        /^https:\/\/zebis\.digital\//,
        /^https:\/\/zebis\.traefik\.me\//,
    ],

    // Capture Replay for 10% of all sessions,
    // plus 100% of sessions with an error
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

createInertiaApp({
    resolve: async (name) => {
        const pages = import.meta.glob<ModuleImportInterface>("./pages/**/*Page.svelte");
        const page = await pages[`./pages/${name}.svelte`]();
        return {
            default: page.default,
            layout: page.layout ? [IconsLayout, page.layout] : IconsLayout,
        };
    },
    setup({ el, App, props }) {
        new App({ target: el, props });
    },
});
